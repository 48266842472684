// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';

const API_URL = process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1/' : 'https://' + window.location.hostname + ':3000/v1/';

class AuthService
{
    login(username : string, password : string)
    {
        return axios.post(API_URL + 'auth/login',
        {
            username: username,
            password: password
        }, { withCredentials: true })
        .then(response =>
        {
            //@ts-ignore
            if (typeof response.data.session != 'undefined' && response.data.session.length > 0) localStorage.setItem('token', response.data.session);
            return response.data;
        });
    }
    logout()
    {
        return axios.post(API_URL + 'auth/logout', {}, { withCredentials: true })
        .then(response =>
        {
            if(response.data.success) localStorage.removeItem('user');
            return response.data;
        });
    }
    register(user : any)
    {
        return axios.post(API_URL + 'auth/register',
        {
            username: user.username,
            email: user.email,
            password: user.password,
            firstname: user.firstname,
            lastname: user.lastname,
            phone: user.phone,
            secretQuestion: user.secretQuestion,
            secretAnswer: user.secretAnswer
        }, { withCredentials: true })
        .then(response =>
        {
            return response;
        });
    }
    saveFCM(fcmToken : string)
    {
        return axios.post(API_URL + 'save_fcm',
        {
            account_data: fcmToken
        }, { withCredentials: true })
        .then(response =>
        {
            return response.data;
        });
    }
}

export default new AuthService();