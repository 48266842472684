
    import { defineComponent } from 'vue';
    import { IonApp, IonRouterOutlet } from '@ionic/vue';
    import { ellipse, square, triangle } from 'ionicons/icons';
    import Header from '@/components/Header.vue';
    import SideMenu from '@/components/SideMenu.vue';
    // import InjectFirebase from '@/components/InjectFirebase.vue';
    import IntroPage from '@/views/IntroPage.vue';
    import LoginPage from '@/views/LoginPage.vue';
    import Printer from './utils/Printer';
    import SocketService from './services/socket.service';
    import { useI18n } from 'vue-i18n';
    // import moment from 'moment-timezone';
    var translate;
    export default defineComponent({
        name: 'App',
        components: { Header, SideMenu, IonRouterOutlet, IonApp, IntroPage, LoginPage },
        setup() {
            const toggleDarkTheme = (shouldAdd: boolean) =>
            {
                document.body.classList.toggle('dark', shouldAdd);
                localStorage.setItem('dark', shouldAdd ? 'true' : 'false');
            };
            let localDarkMode = localStorage.getItem('dark');
            if(localDarkMode != null)
            {
                toggleDarkTheme(localDarkMode == 'true');
            }
            else
            {
                const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
                toggleDarkTheme(prefersDark.matches);
                prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));
            }
            const showIntroPage = false;//!(localStorage.getItem('skip_intro') == '1');
            //
            return {
                ellipse,
                square,
                triangle,
                showIntroPage
            }
        },
        mounted()
        {
            this.createTranslator();
            //@ts-ignore
            this.$root.printer = new Printer(this.$store, translate);
            //@ts-ignore
            this.$root.printer.BindListeners();
            //@ts-ignore
            if (typeof this.$store.state.auth.user != 'undefined' && this.$store.state.auth.user != null) {
                //@ts-ignore
                this.$root.socket = new SocketService();
                //@ts-ignore
                this.$root.socket.initialize(this);
            }
            //@ts-ignore
            // console.log(this.$store.state.auth.user.timezone + (this.$store.state.auth.user.daylight_offset ? this.$store.state.auth.user.daylight_offset : 0));
            //@ts-ignore
            // console.log(moment().utcOffset(this.$store.state.auth.user.timezone + (this.$store.state.auth.user.daylight_offset ? this.$store.state.auth.user.daylight_offset : 0)).format('YYYY-MM-DD HH:mm a'));
        },
        computed:
        {
            currentUser()
            {
                //@ts-ignore
                return this.$store.state.auth.user;
            }
        },
        methods: {
            createTranslator()
            {
                var { t } = useI18n({
                    inheritLocale: false,
                    useScope: 'global',
                    locale: 'fr'
                });
                translate = t;
            }
        }
    });
