// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.

import axios from 'axios';
//@ts-ignore
import process from 'process';
const API_URL = process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1/' : 'https://' + window.location.hostname + ':3000/v1/';

class PricingService
{
	get(id: string)
	{
		return axios.post(`${API_URL}pricing`,
		{
			id: id
		}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	list(status: boolean | null, page: number, search: string)
	{
		return axios.post(`${API_URL}pricing/list`, { status: status, page: page < 1 ? 1 : page, search: search }, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	insert(name: string, description: string, status: boolean, type: string, days: string, hour_min: string | null, hour_max: string | null, date_min: string | null, date_max: string | null, priority: string, zones: string, hourly_rate: string, off_peak_hourly_rate: string | null, off_peak_hour_morning_start: string | null, off_peak_hour_morning_end: string | null, off_peak_hour_evening_start: string | null, off_peak_hour_evening_end: string | null, daily_max_rate: string | null, weekly_max_rate: string | null, monthly_max_rate: string | null, kw_rate: string | null, lost_ticket_rate: string | null)
	{
		return axios.put(`${API_URL}pricing`, {
			name,
			description,
			status,
			type,
			days,
			hour_min,
			hour_max,
			date_min,
			date_max,
			priority,
			zones,
			hourly_rate,
			off_peak_hourly_rate,
			off_peak_hour_morning_start,
			off_peak_hour_morning_end,
			off_peak_hour_evening_start,
			off_peak_hour_evening_end,
			daily_max_rate,
			weekly_max_rate,
			monthly_max_rate,
			kw_rate,
			lost_ticket_rate
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	update(id: string, name: string, description: string, status: boolean, type: string, days: string, hour_min: string | null, hour_max: string | null, date_min: string | null, date_max: string | null, priority: string, zones: string, hourly_rate: string, off_peak_hourly_rate: string | null, off_peak_hour_morning_start: string | null, off_peak_hour_morning_end: string | null, off_peak_hour_evening_start: string | null, off_peak_hour_evening_end: string | null, daily_max_rate: string | null, weekly_max_rate: string | null, monthly_max_rate: string | null, kw_rate: string | null, lost_ticket_rate: string | null)
	{
		return axios.patch(`${API_URL}pricing`, {
			id: id.toString(),
			name,
			description,
			status,
			type,
			days,
			hour_min,
			hour_max,
			date_min,
			date_max,
			priority,
			zones,
			hourly_rate,
			off_peak_hourly_rate,
			off_peak_hour_morning_start,
			off_peak_hour_morning_end,
			off_peak_hour_evening_start,
			off_peak_hour_evening_end,
			daily_max_rate,
			weekly_max_rate,
			monthly_max_rate,
			kw_rate,
			lost_ticket_rate
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	delete(id: string)
	{
		//@ts-ignore
		return axios.delete(`${API_URL}pricing`, { withCredentials: true, data: { id: id.toString() } }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	getForFilters()
	{
		return axios.post(`${API_URL}pricing/filters`, {}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	calculatePricing(zone_id: string, entrance: string, exit: string)
	{
		return axios.post(`${API_URL}pricing/calculate`, { zone_id: zone_id.toString(), entrance: entrance, exit: exit }, { withCredentials: true }).then(response => {
			return response.data;
		});
	}
}

export default new PricingService();
