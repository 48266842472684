import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-900a845a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  style: {"height":"100%","width":"100%"}
}
const _hoisted_2 = {
  key: 2,
  style: {"height":"100%","width":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "pageWrapper"
}
const _hoisted_4 = { class: "mainPanel" }
const _hoisted_5 = { class: "mainPanelContainer" }
const _hoisted_6 = { class: "mainPanelContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IntroPage = _resolveComponent("IntroPage")!
  const _component_LoginPage = _resolveComponent("LoginPage")!
  const _component_Header = _resolveComponent("Header")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, {
    style: _normalizeStyle(_ctx.showIntroPage ? 'background-color: white !important;' : '')
  }, {
    default: _withCtx(() => [
      (_ctx.showIntroPage)
        ? (_openBlock(), _createBlock(_component_IntroPage, { key: 0 }))
        : (!_ctx.currentUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_LoginPage, { style: {"height":"100%","width":"100%"} })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (['/', '/map', '/cameras', '/devices', '/customers', '/guests', '/entries', '/statistics', '/events', '/alerts', '/transactions', '/settings', '/settings/users'].includes(this.$route.path))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Header),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_SideMenu),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_ion_router_outlet)
                        ])
                      ])
                    ])
                  ]))
                : (_openBlock(), _createBlock(_component_ion_router_outlet, { key: 1 }))
            ]))
    ]),
    _: 1
  }, 8, ["style"]))
}