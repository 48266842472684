
    import { defineComponent } from 'vue';
    import { IonIcon }         from '@ionic/vue';
    import { useI18n } from 'vue-i18n';
    import { scanOutline }     from 'ionicons/icons';
    export default defineComponent({
        name: 'CameraWebRTCPlayer',
        props: [
            'url',
            'className',
            'cameraId',
            'controls',
            'muted',
            'autoplay',
            'playbackEnabled',
            'microphoneEnabled',
            'iOSCompatFullScreen',
            'pip',
            'onPlaying',
            'onError'
        ],
        components: { IonIcon },
        setup()
        {
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            return {
                scanOutline, t
            }
        },
        data() {
            return {
                dragged:          false,
                iOSCompatControls: false,
                retryPause:       2000,
                pc:               null,
                ws:               null,
                bufferTimeout:    null,
                isVideoPlaying:   false
            }
        },
        async mounted()
        {
            //@ts-ignore
            // this.initializeWebRTC();
        },
        beforeUnmount()
        {
            // console.log('BEFORE UNMOUNT');
            // this.stop();
        },
        // updated()
        // {
        //     setTimeout(() => this.initializeWebRTC(), 300);
        // },
        watch: {
            // url: {
            //     immediate: true,
            //     handler (val, oldVal) {
            //         //@ts-ignore
            //         var self = this;
            //         //@ts-ignore
            //         var video = self.$refs["videortc"] as HTMLMediaElement;
            //         var fnc = async function() {
            //             //@ts-ignore
            //             if (typeof video != 'undefined') {
            //                 //@ts-ignore
            //                 video.pause();
            //                 //@ts-ignore
            //                 video.currentTime = 0;
            //                 //@ts-ignore
            //                 video.src = undefined;
            //                 //@ts-ignore
            //                 if (self.pc !== null) {
            //                     //@ts-ignore
            //                     self.pc.close();
            //                     //@ts-ignore
            //                     self.pc = null;
            //                 }
            //                 //@ts-ignore
            //                 if (self.ws !== null) {
            //                     //@ts-ignore
            //                     self.ws.close();
            //                     //@ts-ignore
            //                     self.ws = null;
            //                 }
            //                 //@ts-ignore
            //                 await self.initializeWebRTC();
            //                 //@ts-ignore
            //                 video.play();
            //             }
            //         };
            //         fnc();
            //     }
            // }
        },
        methods: {
            visibilityChanged (isVisible, entry) {
                if (isVisible) {
                    //@ts-ignore
                    this.initializeWebRTC();
                } else {
                    stop();
                }
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            async waitForUserGesture() {
                return new Promise((resolve) => {
                    const handler = () => {
                        //@ts-ignore
                        resolve();
                        document.removeEventListener('click', handler);
                    };
                    document.addEventListener('click', handler, { once: true });
                });
            },
            stop()
            {
                //@ts-ignore
                if (this.pc !== null) {
                    //@ts-ignore
                    this.pc.close();
                    //@ts-ignore
                    this.pc = null;
                }
                //@ts-ignore
                if (this.ws !== null) {
                    //@ts-ignore
                    this.ws.close();
                    //@ts-ignore
                    this.ws = null;
                }
            },
            async initializeWebRTC() {
                //@ts-ignore
                if (this.url == '') return;
                //@ts-ignore
                var video            = this.$refs["videortc"] as HTMLMediaElement;
                //@ts-ignore
                video.controls       = this.controls;
                //@ts-ignore
                video.muted          = this.muted || false;
                //@ts-ignore
                video.autoplay       = this.autoplay || true;
                //@ts-ignore
                video.playsInline    = this.playsInline || true;
                video.addEventListener("progress", this.handleProgress);
                //@ts-ignore
                const aPc            = this.PeerConnection(this.microphoneEnabled ? 'video+audio+microphone' : 'video+audio');
                await this.connect(aPc);
            },
            async PeerConnection(media) {
                const pc = new RTCPeerConnection({
                    bundlePolicy: 'max-bundle',
                    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
                });
                const localTracks = [];
                try {
                    if (/camera|microphone/.test(media)) {
                        const tracks = await this.getMediaTracks('user', {
                            video: media.includes('camera'),
                            audio: media.includes('microphone'),
                        });
                        tracks.forEach((track) => {
                            //@ts-ignore
                            this.pc.addTransceiver(track, { direction: 'sendonly' });
                            //@ts-ignore
                            if (track.kind === 'video') localTracks.push(track);
                        });
                    }
                    if (media.includes('display')) {
                        const tracks = await this.getMediaTracks('display', {
                            video: true,
                            audio: media.includes('speaker'),
                        });
                        tracks.forEach((track) => {
                            //@ts-ignore
                            this.pc.addTransceiver(track, { direction: 'sendonly' });
                            //@ts-ignore
                            if (track.kind === 'video') localTracks.push(track);
                        });
                    }
                    if (/video|audio/.test(media)) {
                        const tracks = ['video', 'audio']
                            .filter((kind) => media.includes(kind))
                            .map((kind) => pc.addTransceiver(kind, { direction: 'recvonly' }).receiver.track);
                        //@ts-ignore
                        localTracks.push(...tracks);
                    }
                    //@ts-ignore
                    var video = this.$refs["videortc"] as HTMLMediaElement;
                    video.srcObject = new MediaStream(localTracks);
                    video.srcObject.addEventListener("progress", this.handleProgress);
                    return pc;
                } catch (error) {
                    console.error('Error initializing PeerConnection:', error);
                    return null;
                }
            },
            async connect(aPcPromise) {
                try {
                    const aPc = await aPcPromise;
                    if (!aPc) return;
                    //@ts-ignore
                    this.pc = aPc;
                    //@ts-ignore
                    const ws = new WebSocket(this.url);
                    //@ts-ignore
                    this.ws = ws;
                    ws.binaryType = 'arraybuffer',
                    ws.addEventListener('open', () => {
                        //@ts-ignore
                        this.pc.addEventListener('icecandidate', (ev) => {
                            if (!ev.candidate) return;
                            const msg = {
                                type: 'webrtc/candidate',
                                value: ev.candidate.candidate,
                            };
                            ws.send(JSON.stringify(msg));
                        });
                        //@ts-ignore
                        this.pc.createOffer()
                        //@ts-ignore
                        .then((offer) => this.pc.setLocalDescription(offer))
                        .then(() => {
                            const msg = {
                                type: 'webrtc/offer',
                                //@ts-ignore
                                value: this.pc.localDescription?.sdp,
                            };
                            ws.send(JSON.stringify(msg));
                        });
                    });
                    ws.addEventListener('message', (ev) => {
                        const msg = JSON.parse(ev.data);
                        if (msg.type === 'webrtc/candidate') {
                            if (msg.value != null) {
                                let ipRegex = /\b(?:[0-9]{1,3}\.){3}[0-9]{1,3}\b/;
                                let replacedString = msg.value.replace(ipRegex, '209.172.45.130');
                                //@ts-ignore
                                this.pc?.addIceCandidate({ candidate: replacedString, sdpMid: '0' });
                            }
                        } else if (msg.type === 'webrtc/answer') {
                            if (msg.value != null) {
                                //@ts-ignore
                                this.pc?.setRemoteDescription({
                                    type: 'answer',
                                    sdp: msg.value,
                                });
                            }
                        }
                    });
                } catch (error) {
                    console.error('Error connecting to WebSocket:', error);
                }
            },
            handleLoadedData(event) {
                console.log('loaded data');
            },
            handleProgress(event) {
                console.log('Handling progress...');
                //@ts-ignore
                var video = this.$refs["videortc"] as HTMLMediaElement;
                if (video && video.paused) return;
                //@ts-ignore
                if (this.bufferTimeout) {
                    //@ts-ignore
                    clearTimeout(this.bufferTimeout);
                    //@ts-ignore
                    this.bufferTimeout = null;
                }
                //@ts-ignore
                this.bufferTimeout = setTimeout(() => {
                    console.log('stalled');
                    //@ts-ignore
                    if (document.visibiliytState === 'visible' && this.pc) {
                        //@ts-ignore
                        this.onError?.('stalled');
                    }
                }, 3000);
            },
            handlePlaying(event) {
                console.log('video is playing');
                this.isVideoPlaying = true;
                //@ts-ignore
                this.onPlaying?.();
            },
            handleError(e) {
                console.log('Handling player error: ', e.target.error);
                if (e.target.error.code == MediaError.MEDIA_ERR_NETWORK) {
                    console.log('Media ERR NETWORK');
                    this.onError?.("startup");
                }
            },
            handleClick(event) {
                if (this.iOSCompatFullScreen) {
                    this.iOSCompatControls = !this.iOSCompatControls
                }
            },
            async getMediaTracks(media, constraints) {
                try {
                    const stream = media === "user"
                        ? await navigator.mediaDevices.getUserMedia(constraints)
                        : await navigator.mediaDevices.getDisplayMedia(constraints);
                    return stream.getTracks();
                } catch (e) {
                    return [];
                }
            },
            handleDragStart(e) {
                console.log('dragging RTC video', e)
                //@ts-ignore
                this.dragged = true;
                //@ts-ignore
                this.$root.$draggedCameraComponent = this;
            },
            handleDragEnd(e) {
                console.log('dragged RTC video', e)
                //@ts-ignore
                this.dragged = false;
            },
            fullscreen() {
                //@ts-ignore
                var video  = this.$refs["video"] as HTMLDivElement;
                if (!document.fullscreenElement) {
                    video.requestFullscreen();
                } else {
                    //@ts-ignore
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    //@ts-ignore
                    } else if (document.msExitFullscreen) {
                        //@ts-ignore
                        document.msExitFullscreen();
                    //@ts-ignore
                    } else if (document.mozCancelFullScreen) {
                        //@ts-ignore
                        document.mozCancelFullScreen();
                    //@ts-ignore
                    } else if (document.webkitExitFullscreen) {
                        //@ts-ignore
                        document.webkitExitFullscreen();
                    }
                }
            }
        }
    });
