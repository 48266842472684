// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';

const API_URL = process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1/' : 'https://' + window.location.hostname + ':3000/v1/';

class CameraService
{
	get(id: string)
	{
		return axios.post(`${API_URL}camera`,
		{
			id: id
		}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	list()
	{
		return axios.post(`${API_URL}camera/list`, {}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	insert(name: string, zone_id: number, type: number, path: string, username: string, password: string, showtext: boolean, textpositionx: number, textpositiony: number, textboxpadding: number, textboxopacity: number, fontsize: number, width: number, height: number, maxfps: number, maxrate: number, buffersize: number, audio: boolean)
	{
		return axios.put(`${API_URL}camera`, {
			name,
			zone_id,
			type,
			path,
			username,
			password,
			showtext,
			textpositionx,
			textpositiony,
			textboxpadding,
			textboxopacity,
			fontsize,
			width,
			height,
			maxfps,
			maxrate,
			buffersize,
			audio
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	update(id: string, name: string, zone_id: number, type: number, path: string, username: string, password: string, showtext: boolean, textpositionx: number, textpositiony: number, textboxpadding: number, textboxopacity: number, fontsize: number, width: number, height: number, maxfps: number, maxrate: number, buffersize: number, audio: boolean)
	{
		return axios.patch(`${API_URL}camera`, {
            id,
			name,
			zone_id,
			type,
			path,
			username,
			password,
			showtext,
			textpositionx,
			textpositiony,
			textboxpadding,
			textboxopacity,
			fontsize,
			width,
			height,
			maxfps,
			maxrate,
			buffersize,
			audio
		}, { withCredentials: true }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	delete(id: string)
	{
		//@ts-ignore
		return axios.delete(`${API_URL}camera`, { withCredentials: true, data: { id: id.toString() } }).then(response => {
			return response.data;
		}).catch(function (error) {
			if (error.response)
			{
				return error.response.data;
			}
			return null;
		});
	}

	events(page: number, search: string)
	{
		return axios.post(`${API_URL}camera/events`, {page, search}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}
}

export default new CameraService()