// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';

const API_URL = process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1/' : 'https://' + window.location.hostname + ':3000/v1/';

class UserService
{
    setStore(store: any) {
        //@ts-ignore
        this.$store = store;
    }

    setRouter(router: any) {
        //@ts-ignore
        this.$router = router;
    }

    logout() {
        localStorage.removeItem('user');
        //@ts-ignore
        this.$store.dispatch('auth/logout', { }).then(() =>
            {
                //@ts-ignore
                window.location.href = '/';
            }
        );
    }

    online()
    {
        const self = this;
        return axios.get(process.env.NODE_ENV == 'development' ? 'https://' + window.location.hostname + ':3000/v1' : 'https://' + window.location.hostname + ':3000/v1', { withCredentials: true }).then(response => {
            return {online: true};
        }).catch(function (error) {
            self.logout();
            return null;
        });
    }

    get()
    {
        const self = this;
        return axios.get(`${API_URL}user`, { withCredentials: true }).then(response => {
            if(response.data.success) localStorage.setItem('user', JSON.stringify(response.data.user));
            return response.data;
        }).catch(function (error) {
            self.logout();
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    getById(id: string)
    {
        return axios.post(`${API_URL}user`,
        {
            id: id
        }, { withCredentials: true }).then(response => {
            return response.data;
        }).catch(function (error) {
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    list(page: number, search: string)
    {
        return axios.post(`${API_URL}user/list`, {page, search}, { withCredentials: true }).then(response => {
            return response.data;
        }).catch(function (error) {
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    insert(is_ldap: boolean, username: string, email: string, name: string, password: string, status: boolean, notifications: boolean, role: number, timezone: number, adjust_daylight_timezone: boolean, daylight_offset: number, locale: string)
    {
        return axios.put(`${API_URL}user`, {
            is_ldap,
            username,
            email,
            name,
            password,
            status,
            notifications,
            role,
            timezone,
            adjust_daylight_timezone,
            daylight_offset,
            locale
        }, { withCredentials: true }).then(response => {
            return response.data;
        }).catch(function (error) {
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    update(id: string, is_ldap: boolean, username: string, email: string, name: string, password: string, status: boolean, notifications: boolean, role: number, timezone: number, adjust_daylight_timezone: boolean, daylight_offset: number, locale: string)
    {
        return axios.patch(`${API_URL}user`, {
            id: id.toString(),
            is_ldap,
            username,
            email,
            name,
            password,
            status,
            notifications,
            role,
            timezone,
            adjust_daylight_timezone,
            daylight_offset,
            locale
        }, { withCredentials: true }).then(response => {
            return response.data;
        }).catch(function (error) {
            debugger;
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    delete(id: string)
    {
        //@ts-ignore
        return axios.delete(`${API_URL}user`, { withCredentials: true, data: { id: id.toString() } }).then(response => {
            return response.data;
        }).catch(function (error) {
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    saveFCM(fcmToken : string)
    {
        return axios.post(`${API_URL}save_token`, fcmToken, { withCredentials: true });
    }

    getForFilters()
    {
        return axios.post(`${API_URL}user/filters`, {}, { withCredentials: true }).then(response => {
            return response.data;
        });
    }
}

export default new UserService()