
	import { defineComponent } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { IonContent } from '@ionic/vue';
	let translate;
	export default  defineComponent({
		name: 'LoginPage',
		components: { IonContent },
		setup() {
			const { t } = useI18n({
				inheritLocale: true,
				useScope: 'global'
			});
			translate = t;
			return { t };
		},
		data() {
			var locale = localStorage.getItem('locale');
			//@ts-ignore
			if (typeof locale == 'undefined' || locale == false || locale == null) {
				locale = 'fr';
			}
			return {
				username: '',
				password: '',
				remember: '0',
				loading: false,
				message: '',
				successful: false,
				hostname: '',
				language: locale,
				languageOptions: [
					{ value: 'en', text: 'English' },
					{ value: 'fr', text: 'Français' },
				]
			}
		},
		mounted()
		{
			this.hostname = 'http://' + window.location.hostname + ':3000/v1/';
			this.username = localStorage.getItem('username') || "";
			this.remember = this.username == "" ? "0" : "1";
		},
		methods: {
			handleLogin()
			{
				this.loading = true;
				//@ts-ignore
				//validate fields...
				var isValid = true;
				if (!isValid)
				{
					this.loading = false;
					return;
				}
				if (this.username && this.password)
				{
					localStorage.setItem('locale', this.language);
					//@ts-ignore
					// this.$store.dispatch('auth/register', { username: this.username, password: this.password, email: 'sylvainmartens@gmail.com' }).then(() =>
					// {
					// 	console.log('ok');
					// //@ts-ignore
					// }, error => {
					// 	console.log('error');
					// });
					//@ts-ignore
					this.$store.dispatch('auth/login', { username: this.username.toLocaleLowerCase(), password: this.password }).then((result) =>
					{
						//@ts-ignore
						this.$store.dispatch('user/get', {}).then((response) =>
						{
							//@ts-ignore
							this.$store.state.auth.user = JSON.parse(localStorage.getItem('user'));
							if(this.remember == "1") {
								localStorage.setItem('username', this.username.toLocaleLowerCase());
							}
							this.message = "";
							this.successful = true;
						},
						//@ts-ignore
						error =>
						{
							this.loading = false;
							this.message = (error.response && error.response.data && error.response.data.errors && error.response.data.errors.message) ? error.response.data.errors.message : error.toString();
							this.message = translate(this.message);
						});
					},
					//@ts-ignore
					error =>
					{
						this.loading = false;
						this.message = (error.response && error.response.data && error.response.data.errors && error.response.data.errors.message) ? error.response.data.errors.message : error.toString();
						this.message = translate(this.message);
					});
				}
			}
		}
	});
